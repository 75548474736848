.banner {
    padding-left: 8.59375vw;
    padding-right: 6.0552083vw;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: -12px;
    z-index: -1;
}

.banner-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-height: 25vw;
}
.img-block {
    position: absolute;
    top:0;
    right: 0;
    min-height: 25vw;
    width: 41.40625vw;
}
.img-block img {
    max-width: 100%;
}
.banner-head {
    font-family: 'Poppins Medium';
    font-size: 2.864583vw;
    line-height: 4.270833vw;
    margin-top: 2vw;
}
.banner-subhead {
    font-size: 1.97916vw;
    line-height: 4.27083vw;
    color:#E2C621;
}
.banner-desc {
    font-size:max(1.14583vw);
    line-height: max(1.5625vw);
    margin-top: 10px;
}
.banner-watcho-devices {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: end;
    padding-top: 1.25vw;
}
.banner-watcho-devices > div {
    font-size: 1.04166vw;
    line-height: 1.66666vw;
    font-family: 'Poppins Light';
    width: 7.552083vw;
}

ul.list-devices {
    display: flex;
    gap: 20px;
    align-items: baseline;
}
ul.list-devices li {
    text-align: center;
    font-size: max(10px,0.83333333vw);
}
.banner-right {
    width: 37.5vw;
}
.banner-right img {
    max-width: 100%;
}
.list-devices img {
    max-width: max(3.489583vw);
}

@media screen and (max-width:767px) {
    .img-block {
        position: relative;
        top: 0;
        right: 0;
        min-height: 25vw;
        width: 100%;
    }
    .banner {
        padding-left: 3.472vw;
        padding-right: 3.472vw;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .banner-left {
        width: 100%;
        order:2;
        padding-top: 10vw;
    }
    .banner-right {
        width: 100%;
        order:1
    }
    .heading-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 51%;
        position: absolute;
        top:0
    }
    .banner-desc {
        font-size: max(3.3333333vw);
        line-height: max(6.333333vw);
        margin-top: 10px;
        text-align: center;
    }
    .banner-head {
        font-size: 5.1282051vw;
        line-height: 7.1794871vw;
        margin-top: 3.5vw;
        letter-spacing: 0;
    }
    .banner-subhead {
        font-size: 3.68vw;
        line-height: 6.11vw;
        color: #E2C621;
        text-align: left;
    }
    .banner-watcho-devices {
        gap: 20px;
        align-items: center;
        padding-top: 1.25vw;
        flex-direction: column;
    }
    .banner-watcho-devices > div {
        font-size: 3.58vw;
        line-height: 5vw;
        font-family: 'Poppins Light';
        width: 100%;
        text-align: center;
    }

    .banner-watcho-devices > div p br {
        display: none;
    }

    ul.list-devices {
        display: flex;
        gap: 3.3vw;
        align-items: baseline;
        font-size: 3.05vw;
    }
    .list-devices img {
        max-width: max(12.489583vw);
    }
}