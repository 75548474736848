
.subsCard {
  width: 25.172916vw;
  min-height: 35.409583vw;
  border-radius: 20px;    
  border-radius: 1.0416vw;
  position: relative;
  padding-top:1.927083vw;
  padding-bottom: max(11.875vw);
  cursor: default;
}


.fadein {
    animation: fadein 4s;
    background: #00000020;
    text-align: center;
    padding: 5px;
    border-radius: 18px;
    color: red;
    font-size: 0.729166vw;
    width: 70%;
    margin: 0 auto;
}




@keyframes fadeout
{
  0%{
    opacity:1;
  }
  50%
  {
    opacity:0;
  }

}

@keyframes fadein
{
  0%{
    opacity:0;
  }
  50%
  {
    opacity:1;
  }

}


.subsCard::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: #32323250; */
    border-radius: 1.0416vw;
    z-index: 0;
    transition: all 0.25s;
    top:0;
    background-color: #59595950;
}
.subsCard:hover::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: #59595950; */
    background-color: #c1c1c150;
    border-radius: 1.0416vw;
    z-index: 0;
}
.subsCard > * {
    z-index: 1;
    position: relative;
}
.cardHeader {
  border-bottom: 1px solid #ffffff60;
  margin-left: 1.4583vw;
  margin-right: 1.302083vw;
  display: flex;
  justify-content: space-between;
  padding-bottom: max(10px,1.04166vw);
}
.packageName {
  width: 50%;
  font-size: max(1.71875vw);
  font-family: 'Poppins Light';
  line-height: 1.927083vw;
  text-transform: uppercase;
  font-weight: normal;
  display: flex;
  align-items: end;
  flex-wrap: wrap;
}
.packageName span {
    font-size: max(1.71875vw);
    line-height: inherit;
    font-family: 'Poppins SemiBold';
}
.packagePrice {
    width: 50%;
}
.priceBox {
    display: flex;
    gap: max(.74166vw);
    align-items: center;
    justify-content: flex-end;
}
.discounted {
    font-size: 1.5625vw;
    color: #1AFF00;
    font-family: 'Poppins Medium';
    line-height: max(2.39583vw);
}
.actual {
    font-size: max(12px,0.83333vw);
}
.subsType {
    display: flex;
    gap: 0.5vw;
    justify-content: flex-end;
}
.subsType .radioBox{
    display: flex;
    position: relative;
    align-items: center;
  }

  .subsType .radioBox input[type=radio]{
    position: absolute;
    visibility: hidden;
  }
  
  .subsType .radioBox label, [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
    display: block;
    position: relative;
    font-size: max(10px,0.83333vw);
    margin: 0px auto;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    padding-left: max(13px,1.5625vw);
    color: #ffffff50;
    line-height: max(1.4583vw);
}
  

[type="radio"]:checked + label:before  {
    content: '';
    position: absolute;
    height: max(12px,1.145833vw);
    width: max(12px,1.145833vw);
    top: 0px;
    left: 0px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
    background-image: url('../../assets/images/icons/checked-radio.png');
    background-size: 100%; 
}
[type="radio"]:not(:checked) + label:before{
    content: '';
    position: absolute;
    height: max(12px,1.145833vw);
    width: max(12px,1.145833vw);
    top: 0px;
    left: 0px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
    background-size: 100%;
    background-image: url('../../assets/images/icons/unchecked-radio.png');
}

  input[type=radio]:checked ~ .check::before{
    background: #fff;
  }

  input[type=radio]:checked ~ label{
    color: #ffffff;
  }
  .cardBody {
    padding-top:max(20px,1.97916vw);
    text-align: center;
    padding-left:max(10px,1.0416vw);
    padding-right:max(10px,1.0416vw)
  }
  .cardBody h4 {
    font-family: 'Poppins Light';
    font-size: max(18px,1.5625vw);
    font-weight: normal;
    line-height: max(32px,2.39583vw);
  }
  .cardBody p {
    font-size: max(14px,1.1458333vw);
    font-weight: normal;
    line-height: max(20px,1.927083vw);
}
  .platforms-list {
    padding-top: max(20px,1.822916vw);
    padding-bottom: max(20px,1.822916vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.45vw;
    flex-grow: 1;
    margin: auto;
}
  .platforms-list li {
    max-width: max(3.144583vw);
    display: flex;
    align-items: center;
    position: relative;
  }

  .delet_arrow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000ba;
    transition: 0.5s;
    opacity: 0;
}

.delet_arrow a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}


.platforms-list li:hover .delet_arrow{
  opacity: 1;
}

  .addmore {
    font-size: max(10px,0.729166vw);
    line-height: max(14px,0.885416vw);
    height: max(40px,3vw);
    width: max(40px,3.645833vw);
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #ffffff44
  }

  .platforms-list.greate-10 {
    width: 100%;
  }
  .platforms-list.equal-10  {
    width: 90%;
  }
  .platforms-list.equal-8  {
    width: 80%;
  }
  .platforms-list.equal-6  {
    width: 60%;
  }
  .platforms-list li img {
    max-width: 100%;
    border: 0.10416666vw solid #ffffff44;
    border-radius: 1px;
}


.changeOtts {
  cursor: pointer;
  font-size: max(12px, 0.8333333vw);
  display: flex;
  justify-content: center;
  align-items: center;
  color:#ffffff50;
  margin-bottom: 5px;
}
.changeOtts span {
  margin-left:5px;
  text-decoration: underline;
}
.changeOtts img {
  max-width: max(16px,1.145833vw);
}
  .btn-subs {
    width: 17.560416vw;
    height: 3.64583vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E42956;
    border-radius: max(10px,0.78125vw);
    color: #fff;
    font-size: max(16px,1.25vw);
    outline: 0;
    border-width: 0;
    margin:max(20px,1.822916vw)  auto;
    cursor: pointer;
}
a.btn-subs.active-subscription {
  margin-top: max(10px,0.822916vw);
}
.btn-readmore {
    color:#FF98B1;
    font-size: 16px;
    line-height: 25px;
    text-decoration: none;
    background:none;
    outline: 0;
    border: 0;
    background: none;
    border: 0;
    color: #ff98b1;
    font-size: max(10px,.83333vw);
    line-height: 25px;
}
.btn-readmore::after {
    content: ">>";
    padding-left: 7px;
    line-height: 20px;
}
.bestBuy {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  top:0;
}
.bestBuy-wrap {
  width: max(100px,7.2916vw);
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;

}


.platforms-list{position: relative !important;}

.plan-ott-plateform li:nth-child(4) {
  /* width: 2vw; */
  /* position: absolute;
  top: 51px;
  right: 0; */

}




.bestBuy-wrap img {
  max-width: 100%;
}
.bestBuy-wrap span {
  font-size: max(10px,0.83333vw);
  position: absolute;
  font-family: 'Poppins Medium';
}

.bottomWrap {
  display: block;
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
@media screen and (max-width:1600px) {

  .subsType .radioBox .check {
    height: 18px;
    width: 18px;
  }

  .subsType .radioBox .check::before {
    height: 8px;
    width: 8px;
  }

  /* .plan-ott-plateform li:nth-child(4) {
    top: 45px;
  }
  .plan-ott-plateform li:nth-child(4){position: a;} */

}


@media screen and (max-width:1536px) {

  /* .plan-ott-plateform li:nth-child(4) {
  top: 42px; 
}*/

}


@media screen and (max-width:1440px) {

  /* .plan-ott-plateform li:nth-child(4) {
    top: 36px;
  } */

  }


  @media screen and (max-width:1280px) {

    /* .plan-ott-plateform li:nth-child(4) {
      top: 33px;
    } */

    }

/* @media screen and (max-width:1024px) {

  .packageName {
    font-size: max(1.51875vw);
    line-height: 0.927083vw;
  }
  .packageName span {
    font-size: max(1.51875vw);
  }

  .discounted {
    font-size: 1.2625vw;
  }

  .plan-ott-plateform li:nth-child(4) {
    top: 26px;
}

.subsCard {
  width: 48%;

}
.platforms-list.equal-6 {
  width: 38%;
}

} */

@media only screen and (max-width: 991px)  and (min-width:768px)  {

  .subsCard {
    width: 48%;
    min-height: 35.989583vw;
    border-radius: 1.0416vw;
    position: relative;
    padding-top: 2.34375vw;
    padding-bottom: 15px;
  }
  .platforms-list.equal-6 {
    width: 55%;
}
.addmore {
  font-size: max(9px,0.729166vw);
  width: 100%;
  height: max(5.44583vw);
}

.ReactModal__Content .subsplan-btn {
    margin-top: 0;
    width: max(17.989583vw);
    height: max(5.5416vw);
}
.apps-block p {
  font-size: max(14px, 0.83333vw);
  line-height: max(32px, 4.1875vw);
  color: #1C1C1C;
  margin-top: max(5px, 1.57vw);
}

.fadein {
  animation: fadein 4s;
  background: #00000020;
  text-align: center;
  padding: 8px;
  border-radius: 16px;
  color: red;
  font-size: 1.729166vw;
  width: 70%;
  margin: 0 auto;
}


}

@media only screen and (max-width: 991px)  {
  .subsCard {
    width: 48%;
    padding-top: 25px;
    padding-bottom: max(17.875vw);
}
  .subsType .radioBox .check {
    height: 13px;
    width: 13px;
    left: 8px;
    top: 2px;
}

.packagePrice {
  width: 60%;
}

  input[type=radio]:checked ~ .check {
    border: 1px solid #fff;
}

.subsType .radioBox .check::before {
  height: 5px;
  width: 5px;
}

.actual {
  font-size: max(10px,0.83333vw);
}

.packageName {
  font-size: max(16px,1.31875vw);
}

.packageName span {
  font-size: max(16px,1.31875vw);
}

.cardBody h4 {
  font-size: max(14px,1.5625vw);
}
.cardBody p {
  font-size: max(12px,1.1458333vw);
  line-height: max(18px,1.927083vw);
}

.addmore {
  font-size: max(9px,0.729166vw);
}

.saveUp {
  font-size: max(12px,1.5625vw);
}

.btn-subs {
  font-size: max(14px,1.25vw);
}

.btn-readmore {
  font-size: 13px;
}

.sub-heading {
  font-size: max(16px, 1.97916vw);
}

.setpBoxTet > span {
  font-size: max(11px,1.04166vw);
}
.discounted {
  font-size: 18px;
}
.platforms-list li {
  max-width: max(5.44583vw);
}
.subsType {
  display: flex;
  gap: 1.01vw;
  justify-content: flex-end;
  margin-top: 5px;
}
.subsType .radioBox label, [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
  font-size: max(10px,1.083333vw);
  padding-left: max(19px,1.5625vw);
  color: #ffffff50;
  line-height: max(1.8583vw);
}
[type="radio"]:not(:checked) + label:before,[type="radio"]:checked + label:before {
  height: max(14px,1.145833vw);
  width: max(14px,1.145833vw);
}


}






@media screen and (max-width:767px) {
  .subsCard {
    width: 100%;
    min-height: 130.55vw;
    border-radius: 3.333vw;
    position: relative;
    padding-top: 8.333vw;
    padding-bottom: 15px;
}
.subsCard::before {
  border-radius: 3.333vw;
}
.subsCard:hover::before {
  border-radius: 3.333vw;
}
.cardHeader {
  margin-left: 26px;
  margin-right: 26px;
  padding-bottom: max(2.77vw);
  flex-direction: column;
}
.packageName {
  width: 56%;
  font-size: max(5.6410256vw);
  font-family: 'Poppins Light';
  line-height: 6.666vw;
  text-transform: capitalize;
  font-weight: normal;
}
.packageName span {
  font-size: max(5.6410256vw);
  padding-left: 5px;
}
.packagePrice {
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4.722vw;
  padding-bottom: 10px;
}
.priceBox {
  display: flex;
  gap: max(1.04166vw);
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
}
.discounted {
  font-size: 5.6410vw;
  line-height: max(8.46153846vw);
  order: 0;
}
.actual {
  font-size: max(3.07692vw);
}
.subsType {
  align-items: center;
  display: flex;
  gap: 4.35416vw;
  justify-content: center;
}

.subsType .radioBox label, [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
  font-size: max(3.3333vw);
  padding-left: max(5.641025vw);
  color: #ffffff50;
  line-height: max(4.041025vw);
}
[type="radio"]:not(:checked) + label:before, [type="radio"]:checked + label:before {
  height: max(4.10256vw);
  width: max(4.10256vw);
}

.cardBody h4 {
  font-size: 5.555vw;
  font-weight: normal;
  line-height: 8.333vw;
}
.cardBody p {
  font-size: 3.611vw;
  font-weight: normal;
  line-height: 5vw;
  max-width: 75%;
  margin: auto;
}
.platforms-list {
  padding-top: max(20px,1.822916vw);
  padding-bottom: max(20px,1.822916vw);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.388vw;
  flex-grow: 1;
  margin: auto;
}
.platforms-list li {
  width: 11.6384vw;
  display: flex;
  max-width: 100%;
}




.addmore {
  font-size: max(3.076923vw);
  line-height: max(3.076923vw);
  height: max(11.6384vw);
  width: max(11.6384vw);
}
.platforms-list li img {
  width: 11.6384vw;
}
.saveUp {
  font-size: 3.611vw;
  line-height: 5.555vw;
}
.btn-subs {
  width: 60.277vw;
  height: 13.333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E42956;
  border-radius: max(2.777vw);
  color: #fff;
  font-size: max(5vw);
  outline: 0;
  border-width: 0;
  margin: max(20px,1.822916vw) auto;
  cursor: pointer;
}
.btn-readmore {
  font-size: 3.611vw;
  line-height: 5.555vw;
}
.faqsSec {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: max(50px,6.25vw);
}
.faq__question dt {
  font-size: 4.444vw;
  line-height: 6.111vw;
}
.plan-ott-plateform li:nth-child(6) {
  width: 2vw;
}


.platformname {
  font-size: 2.52051vw;
  height: 8vw;
  line-height: 3.15384vw;
}

/* .ott-list li {
  display: flex;
  flex-direction: column;
  width: calc(16.66667% - 2.5vw);
} */

.ott-list li {
  display: flex;
  flex-direction: column;
  width: 15.82051vw;
}

.fadein {
  font-size: 3.329166vw;
  width: 80%;
}

.platforms-list.equal-6 {
  width: 60%;
}

}

.add-more-btn {
  cursor: pointer;
}
.btn-subs {
  text-decoration : none;
}

.inactive-subscription {
  background: grey;
  cursor: default;
}